<template>
  <Traces />
</template>

<script>
import { mapGetters } from 'vuex'
import Traces from '../../dashboard/components/Traces.vue'

export default {
  components: {
    Traces,
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
  },
}
</script>
