<template>
  <div
    v-if="(sabbaticals.data && sabbaticals.data.data.length > 0) || loggedUser.roles.includes('super-admin')"
    class="col-md-4 card card--profile-contact-information"
  >
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="clearfix">
            <h3 class="float-start">
              <i
                data-feather="umbrella"
                class="w-icon-24"
              /> Sabbaticals
            </h3>
            <span
              v-if="loggedUser.roles.includes('super-admin')"
              class="float-end"
            >
              <span
                class="dis-ib"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add supplement or advanced payment"
              ><a
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                @click="$router.push({name: 'researcher.administration.sabbaticals.create'})"
              ><i data-feather="plus" /></a></span>
            </span>
          </div>
          <hr>
        </div>
      </div>
      <div
        v-if="loading"
        class="loading position-relative"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
      <div
        v-else
        class="table-responsive-sm overflow-scroll"
      >
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Begin date</th>
              <th>End date</th>
              <th>Purpose</th>
              <th>Status</th>
              <!-- <th>Documents</th> -->
              <th
                v-if="loggedUser.roles.includes('super-admin')"
                class="text-end"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(sabbatical, index) in sabbaticals.data.data"
              :key="index"
              class="h-57"
            >
              <td>{{ sabbatical.begin_date }}</td>
              <td>{{ sabbatical.end_date }}</td>
              <td v-html="sabbatical.purpose" />
              <td>
                <span
                  v-if="sabbatical.status"
                  :class="`badge bg-${sabbatical.status.badge_class}`"
                >{{ sabbatical.status.status }}</span>
                <span v-else>----</span>
              </td>
              <!-- <td><span :class="`badge bg-${sabbatical.files_.length > 0 ? 'success' : 'danger'}`">{{ sabbatical.files_.length }}</span></td> -->
              <td
                v-if="loggedUser.roles.includes('super-admin')"
                class="text-end"
              >
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                ><a
                  class="btn btn-icon rounded-circle btn-flat-dark"
                  @click="$router.push({name: 'researcher.administration.sabbaticals.edit', params: { id: sabbatical.id }})"
                ><i data-feather="edit-3" /></a></span>
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete"
                ><a
                  class="btn btn-icon rounded-circle btn-flat-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDeleteWithInput"
                  @click="showDeleteModal(sabbatical.id)"
                ><i data-feather="trash-2" /></a></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="showingAll"
        class="card-body"
      >
        <div class="d-flex align-items-center justify-content-between">
          <b-pagination
            v-model="paginator.currentPage"
            :total-rows="sabbaticals.total"
            :per-page="paginator.perPage"
            aria-controls="table"
            @change="changePage"
          />
        </div>
      </div>
      <div
        v-if="sabbaticals.total > minValues"
        class="d-flex align-items-center justify-content-between"
      >
        <a
          class="btn btn-sm btn-flat-secondary"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Display all blocks"
          @click="showAllData"
        >
          {{ showingAll ? 'Show less' : 'Show all' }} <i data-feather="eye" /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BPagination,
  },
  data() {
    return {
      loading: true,
      paginator: {
        perPage: 10,
        currentPage: 1,
      },
      showingAll: false,
      minValues: 3,
      maxValues: 10,
    }
  },
  computed: {
    ...mapGetters({
      sabbaticals: 'users/sabbaticals',
      loggedUser: 'auth/admin',
      user: 'users/user',
    }),
  },
  async mounted() {
    await this.$store.dispatch('users/fetchUserSabbaticals', { id: this.user.id })
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    this.loading = false
  },
  methods: {
    async showDeleteModal(id) {
      Vue.swal({
        title: 'Are you sure?',
        text: 'This action cannot be reversed',
        icon: 'warning',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        closeOnConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/sabbaticals/${id}`)
          Vue.swal('Deleted successfully!', '', 'success').then(() => {
            this.$store.dispatch('users/fetchUserSabbaticals', { id: this.user.id })
            // eslint-disable-next-line no-undef
            feather.replace({
              width: 14,
              height: 14,
            })
          })
        }
      })
    },
    async changePage(page) {
      this.loading = true
      await this.$store.dispatch('users/fetchUserSabbaticals', { id: this.user.id, page, perPage: this.maxValues })
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    async showAllData() {
      this.showingAll = !this.showingAll
      this.loading = true
      if (this.showingAll) {
        this.paginator.currentPage = 1
        await this.$store.dispatch('users/fetchUserSabbaticals', { id: this.user.id, page: this.paginator.currentPage, perPage: this.maxValues })
      } else {
        await this.$store.dispatch('users/fetchUserSabbaticals', { id: this.user.id, perPage: this.minValues })
      }
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
  },
}
</script>
