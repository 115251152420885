<template>
  <div
    v-if="(salaryHistories.data && salaryHistories.data.data.length > 0) || loggedUser.roles.includes('super-admin')"
    class="col-md-6 col-xl-4 col__administrative-data"
  >
    <div class="card card--profile-contact-information">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="clearfix">
              <h3 class="float-start">
                <i
                  data-feather="dollar-sign"
                  class="w-icon-24"
                /> Salary history
              </h3>
              <span
                v-if="loggedUser.roles.includes('super-admin')"
                class="float-end"
              >
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Add salary"
                ><a
                  class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                  @click="$router.push({name: 'researcher.administration.salary-history.create'})"
                ><i data-feather="plus" /></a></span>
              </span>
            </div>
            <hr>
          </div>
        </div>
        <div
          v-if="loading"
          class="loading position-relative"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
        <div
          v-else
          class="table-responsive-sm overflow-scroll"
        >
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Reason</th>
                <th>New salary date</th>
                <th v-if="loggedUser.roles.includes('super-admin')">
                  Levels
                </th>
                <th v-if="loggedUser.roles.includes('super-admin')">
                  Confirm
                </th>
                <th>Related document</th>
                <th
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="text-end"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(salary, index) in salaryHistories.data.data"
                :key="index"
                class="h-57"
              >
                <td><strong>{{ salary.reason }}</strong></td>
                <td>{{ salary.new_salary_level_date }}</td>
                <td v-if="loggedUser.roles.includes('super-admin')">
                  {{ salary.levels }}
                </td>
                <td v-if="loggedUser.roles.includes('super-admin')">
                  <span :class="`badge bg-light-${salary.confirm ? 'success' : 'danger'}`">{{ salary.confirm }}</span>
                </td>
                <td>
                  <a
                    v-if="salary.main_file"
                    class="btn btn-sm btn-flat-dark"
                    target="_blank"
                    :href="salary.main_file.url"
                  ><i data-feather="file" /></a>
                </td>
                <td
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="text-end"
                >
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-dark"
                    @click="$router.push({name: 'researcher.administration.salary-history.edit', params: { id: salary.id }})"
                  ><i data-feather="edit-3" /></a></span>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#modalDeleteWithInput"
                    @click="showDeleteModal(salary.id)"
                  ><i data-feather="trash-2" /></a></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="showingAll"
          class="card-body"
        >
          <div class="d-flex align-items-center justify-content-between">
            <b-pagination
              v-model="paginator.currentPage"
              :total-rows="salaryHistories.total"
              :per-page="paginator.perPage"
              aria-controls="table"
              @change="changePage"
            />
          </div>
        </div>
        <div
          v-if="salaryHistories.total > minValues"
          class="d-flex align-items-center justify-content-between"
        >
          <a
            class="btn btn-sm btn-flat-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Display all blocks"
            @click="showAllData"
          >
            {{ showingAll ? 'Show less' : 'Show all' }} <i data-feather="eye" /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BPagination,
  },
  data() {
    return {
      loading: true,
      paginator: {
        perPage: 10,
        currentPage: 1,
      },
      showingAll: false,
      minValues: 3,
      maxValues: 10,
    }
  },
  computed: {
    ...mapGetters({
      salaryHistories: 'users/salaryHistories',
      loggedUser: 'auth/admin',
      user: 'users/user',
    }),
  },
  async mounted() {
    await this.$store.dispatch('users/fetchUserSalaryHistories', { id: this.user.id })
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    this.loading = false
  },
  methods: {
    async showDeleteModal(id) {
      Vue.swal({
        title: 'Are you sure?',
        text: 'This action cannot be reversed',
        icon: 'warning',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        closeOnConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/salary-history/${id}`)
          Vue.swal('Deleted successfully!', '', 'success').then(() => {
            this.$store.dispatch('users/fetchUserSalaryHistories', { id: this.user.id })
            // eslint-disable-next-line no-undef
            feather.replace({
              width: 14,
              height: 14,
            })
          })
        }
      })
    },
    async changePage(page) {
      this.loading = true
      await this.$store.dispatch('users/fetchUserSalaryHistories', { id: this.user.id, page, perPage: this.maxValues })
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    async showAllData() {
      this.showingAll = !this.showingAll
      this.loading = true
      if (this.showingAll) {
        this.paginator.currentPage = 1
        await this.$store.dispatch('users/fetchUserSalaryHistories', { id: this.user.id, page: this.paginator.currentPage, perPage: this.maxValues })
      } else {
        await this.$store.dispatch('users/fetchUserSalaryHistories', { id: this.user.id, perPage: this.minValues })
      }
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
  },
}
</script>

<style>

</style>
