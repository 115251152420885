<template>
  <div
    v-if="(otherAgreements.data && otherAgreements.data.data.length > 0) || loggedUser.roles.includes('super-admin')"
    class="col-md-4 card card--table"
  >
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="clearfix">
            <h3 class="float-start">
              <i
                data-feather="file"
                class="w-icon-24"
              />Others agreements and documents
            </h3>
            <span
              v-if="loggedUser.roles.includes('super-admin')"
              class="float-end"
            > <!-- only admin -->
              <span
                class="dis-ib"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add document"
              >
                <a
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasAddOtherDocument"
                  aria-controls="offcanvasAddOtherDocument"
                  class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                  @click="openUploadAgreement($event)"
                ><i data-feather="plus" /></a>
              </span>
            </span>
          </div>
          <hr>
        </div>
      </div>
      <div
        v-if="loading"
        class="loading position-relative"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
      <div
        v-else
        class="table-responsive-sm overflow-scroll"
      >
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Document</th>
              <th>Begin date</th>
              <th>End date</th>
              <th>File</th>
              <th
                v-if="loggedUser.roles.includes('super-admin')"
                class="text-end"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(otherAgreement, index) in otherAgreements.data.data"
              :key="index"
              class="h-57"
            >
              <td>{{ otherAgreement.name }}</td>
              <td>{{ otherAgreement.begin_date }}</td>
              <td>{{ otherAgreement.end_date }}</td>
              <td>
                <span
                  v-if="otherAgreement.files.length > 0"
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="View file"
                ><a
                  :href="otherAgreement.files[0].url"
                  target="_blank"
                  class="btn btn-icon rounded-circle btn-flat-dark"
                ><i data-feather="file" /></a></span>
              </td>
              <td
                v-if="loggedUser.roles.includes('super-admin')"
                class="text-end"
              >
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                ><a
                  class="btn btn-icon rounded-circle btn-flat-dark"
                  @click="openUploadAgreement($event, otherAgreement)"
                ><i data-feather="edit-3" /></a></span>
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete"
                ><a
                  href="#"
                  class="btn btn-icon rounded-circle btn-flat-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDeleteWithInput"
                  @click="showDeleteModal(otherAgreement.id)"
                ><i data-feather="trash-2" /></a></span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="showingAll"
          class="card-body"
        >
          <div class="d-flex align-items-center justify-content-between">
            <b-pagination
              v-model="paginator.currentPage"
              :total-rows="otherAgreements.total"
              :per-page="paginator.perPage"
              aria-controls="table"
              @change="changePage"
            />
          </div>
        </div>
        <div
          v-if="otherAgreements.total > minValues"
          class="d-flex align-items-center justify-content-between"
        >
          <a
            class="btn btn-sm btn-flat-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Display all blocks"
            @click="showAllData"
          >
            {{ showingAll ? 'Show less' : 'Show all' }} <i data-feather="eye" /></a>
        </div>
      </div>
    </div>
    <ItemInfoSheet :table-id="otherAgreementInfoSheet">
      <template #info-sheet-title>
        Add Other document
      </template>
      <template
        #info-sheet
      >
        <div class="offcanvas-body">
          <div class="mb-1 form-group required">
            <label
              for=""
              class="form-label"
            >Document name</label>
            <input
              v-model="newAgreement.name"
              required
              type="text"
              class="form-control"
            >
          </div>
          <div class="mb-1 form-group required">
            <label
              for=""
              class="form-label"
            >Begin date</label>
            <date-picker
              v-model="newAgreement.begin_date"
              format="D MMM Y"
              value-type="format"
              class="w-100"
            />
          </div>
          <div class="mb-1 form-group required">
            <label
              for=""
              class="form-label"
            >End date</label>
            <date-picker
              v-model="newAgreement.end_date"
              format="D MMM Y"
              value-type="format"
              class="w-100"
            />
          </div>
          <hr>
          <div class="mb-1 col-12 form-group required">
            <label
              :for="`formFile-agreement`"
              class="form-label"
            >Upload file</label>
            <input
              :id="`formFile-agreement`"
              :ref="`file-agreement`"
              class="form-control"
              type="file"
            >
          </div>
        </div>
        <div class="offcanvas-footer mt-auto">
          <button
            type="button"
            class="btn btn-success mb-1 d-grid w-100"
            @click="addDocument"
          >
            Upload document
          </button>
        </div>
      </template>
    </ItemInfoSheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BPagination } from 'bootstrap-vue'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Vue from 'vue'

export default {
  components: {
    ItemInfoSheet,
    DatePicker,
    BPagination,
  },
  data() {
    return {
      loading: true,
      newAgreement: {
        files: [],
      },
      paginator: {
        perPage: 10,
        currentPage: 1,
      },
      showingAll: false,
      minValues: 3,
      maxValues: 10,
      otherAgreementInfoSheet: 3,
    }
  },
  computed: {
    ...mapGetters({
      otherAgreements: 'users/otherAgreements',
      loggedUser: 'auth/admin',
      user: 'users/user',
    }),
  },
  async mounted() {
    await this.$store.dispatch('users/fetchUserOtherAgreements', { id: this.user.id })
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    this.loading = false
  },
  methods: {
    openUploadAgreement(event, item = null) {
      event.stopPropagation()
      if (!item) {
        this.newAgreement = {
          files: [],
        }
      } else {
        // Editas el objeto en tiempo real, no me gusta
        this.newAgreement = item
      }
      this.$store.dispatch('modals/toggleTableClicked', this.otherAgreementInfoSheet)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async addDocument() {
      const file = this.$refs['file-agreement'].files[0]
      if (file || this.newAgreement.files.length > 0) {
        if (file) {
          this.newAgreement.files.push({
            name: this.newAgreement.name,
            original_name: file.name,
            weight: (file.size / 1024 / 1024).toFixed(2),
            extension: file.type,
            file,
          })
        }
        const otherDocument = {
          otherAgreement: this.newAgreement,
          user_id: this.user.id,
        }
        try {
          if (!this.newAgreement.id) {
            await this.$store.dispatch('users/uploadContract', otherDocument)
          } else {
            await this.$store.dispatch('users/updateContract', otherDocument)
          }
          Vue.swal('Document uploaded successfully!', '', 'success').then(async () => {
            this.loading = true
            await this.$store.dispatch('users/fetchUserOtherAgreements', { id: this.user.id })
            this.loading = false
            setTimeout(() => {
              // eslint-disable-next-line no-undef
              feather.replace({
                width: 14,
                height: 14,
              })
            }, 500)
          })
        } catch (e) {
          this.$toastr.error('', 'Error uploading document')
        }

        this.newAgreement = {
          files: [],
        }
        this.$store.dispatch('modals/toggleInfoItemSheet', false)
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      } else {
        Vue.swal('Please, upload a file first!', '', 'warning')
      }
    },
    async showDeleteModal(id) {
      Vue.swal({
        title: 'Are you sure?',
        text: 'This action cannot be reversed',
        icon: 'warning',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        closeOnConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/other-agreements/${id}`)
          Vue.swal('Deleted successfully!', '', 'success').then(() => {
            this.$store.dispatch('users/fetchUserOtherAgreements', { id: this.user.id })
            // eslint-disable-next-line no-undef
            feather.replace({
              width: 14,
              height: 14,
            })
          })
        }
      })
    },
    async changePage(page) {
      this.loading = true
      await this.$store.dispatch('users/fetchUserOtherAgreements', { id: this.user.id, page, perPage: this.maxValues })
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    async showAllData() {
      this.showingAll = !this.showingAll
      this.loading = true
      if (this.showingAll) {
        this.paginator.currentPage = 1
        await this.$store.dispatch('users/fetchUserOtherAgreements', { id: this.user.id, page: this.paginator.currentPage, perPage: this.maxValues })
      } else {
        await this.$store.dispatch('users/fetchUserOtherAgreements', { id: this.user.id, perPage: this.minValues })
      }
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
  },
}
</script>

<style>

</style>
