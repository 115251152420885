<template>
  <div
    id="work"
    class="tab-pane "
    aria-labelledby="work-tab"
    role="tabpanel"
  >
    <Sabbaticals />
  </div>
</template>

<script>
import Sabbaticals from '@/views/back/partials/components/administrative-data/Sabbaticals.vue'

export default {
  components: {
    Sabbaticals,
  },
}
</script>
