<template>
  <div
    v-if="!loading"
    class="app-content content "
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <!-- TITLE -->
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <div class="avatar avatar-custom avatar-xl float-start">
                <img
                  :src="user.avatarUrl ? user.avatarUrl : require('@/assets/images/avatars/user_default.png')"
                  alt="avatar"
                >
              </div>
              <h2 class="content-header-title  mb-0">
                {{ user.name }}
                <span>{{ user.areas ? `${user.areas.name}` : '' }}</span>
                <template v-if="user.current_institution">
                  <span>{{ user.current_institution.code }}</span>
                  <!-- <span>{{ user.areas && user.areas[0] ? user.areas[0].code : '' }}</span> -->
                  <span>{{ user.current_institution.pivot && user.current_institution.pivot.department_aux ? user.current_institution.pivot.department_aux.name : '' }}</span>
                </template>
              </h2>
            </div>
          </div>

        </div>
        <!-- QUICK ACTIONS -->
        <div
          v-if="user.roles.includes('super-admin')"
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <div class="btn-group">
              <button
                id="dropdownMenuButton"
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Quick actions <i data-feather="chevron-down" />
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  class="dropdown-item"
                  href="#"
                >Edit CV</a>
                <a
                  class="dropdown-item"
                  href="#"
                >Scientific Production Report</a>
                <a
                  class="dropdown-item"
                  href="#"
                >Last Payrool</a>
                <a
                  class="dropdown-item"
                  href="#"
                >View abridged CV</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <BasicData
            :user="user"
            :col-size="6"
          />
          <Contracts
            :user="user"
            :col-size="6"
          />
          <div class="tab-content">
            <b-tabs lazy>
              <b-tab
                active
              >
                <template #title>
                  Salary & Promotion history
                </template>
                <!-- Salary -->
                <SalaryTab
                  v-if="!loading"
                />
              </b-tab>
              <b-tab>
                <template #title>
                  Others Contracts and documents
                </template>
                <!-- Occupational Hazards -->
                <OtherContractsTab
                  v-if="!loading"
                />
              </b-tab>
              <b-tab>
                <template #title>
                  Sabbaticals
                </template>
                <!-- Work -->
                <SabbaticalsTab
                  v-if="!loading"
                />
              </b-tab>
              <b-tab v-if="loggedUser.roles.includes('super-admin')">
                <template #title>
                  Traces
                </template>
                <!-- Traces -->
                <TracesTab
                  v-if="!loading"
                />
              </b-tab>
              <b-tab>
                <template #title>
                  All
                </template>
                <!-- All -->
                <AllTab
                  v-if="!loading"
                />
              </b-tab>
            </b-tabs>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BTab, BTabs } from 'bootstrap-vue'
import BasicData from './components/BasicData.vue'
import Contracts from './components/Contracts.vue'
import SalaryTab from './components/SalaryTab.vue'
import SabbaticalsTab from './components/SabbaticalsTab.vue'
import OtherContractsTab from './components/OtherContractsTab.vue'
import TracesTab from './components/TracesTab.vue'
import AllTab from './components/AllTab.vue'

export default {
  components: {
    BasicData,
    Contracts,
    BTab,
    BTabs,
    SalaryTab,
    SabbaticalsTab,
    OtherContractsTab,
    TracesTab,
    AllTab,
  },
  data() {
    return {
      userId: this.$route.params.id,
      openEdit: false,
      loading: true,
      icon_path: '',
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      user: 'users/user',
    }),
  },
  mounted() {
    this.icon_path = this.user ? this.user.avatarUrl : ''
    this.reloadDashboard()
    if (this.loggedUser.roles.includes('super-admin') && this.$route.query.prefilterUser) {
      this.$store.dispatch('modals/showAdminMenu', false)
    }
  },
  methods: {
    async reloadDashboard() {
      if (this.userId && this.loggedUser.roles.includes('super-admin')) {
        await this.$store.dispatch('users/fetchUser', this.userId)
      } else if (this.$route.query.prefilterUser && this.loggedUser.roles.includes('super-admin')) {
        await this.$store.dispatch('users/fetchUser', this.$route.query.prefilterUser)
      } else {
        await this.$store.dispatch('users/fetchUser', this.loggedUser.id)
      }

      if (Array.isArray(this.user.areas)) {
        [this.user.areas] = this.user.areas
      }

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
  },
}
</script>

<style>

</style>
