<template>
  <div
    id="ocupational"
    class="tab-pane "
    aria-labelledby="ocupational-tab"
    role="tabpanel"
  >
    <div class="row row__administrative-data d-flex align-items-stretch">
      <OtherContracts />

      <OthersAgreements />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OtherContracts from '@/views/back/partials/components/administrative-data/OtherContracts.vue'
import OthersAgreements from '@/views/back/partials/components/administrative-data/OthersAgreements.vue'

export default {
  components: {
    OtherContracts,
    OthersAgreements,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    this.loading = false
  },
}
</script>
