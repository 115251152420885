<template>
  <div
    id="allTab"
    class="tab-pane"
    aria-labelledby="all-tab"
    role="tabpanel"
  >
    <SalaryTab />

    <OtherContractsTab />

    <SabbaticalsTab />

    <TracesTab
      v-if="loggedUser.roles.includes('super-admin')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SalaryTab from './SalaryTab.vue'
import SabbaticalsTab from './SabbaticalsTab.vue'
import OtherContractsTab from './OtherContractsTab.vue'
import TracesTab from './TracesTab.vue'

export default {
  components: {
    SalaryTab,
    SabbaticalsTab,
    OtherContractsTab,
    TracesTab,
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
  },
}
</script>
