<template>
  <div
    v-if="loggedUser.roles.includes('super-admin')"
    class="col-6 card card--profile-contact-information"
  >
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="clearfix">
            <h3 class="float-start">
              <i
                data-feather="hard-drive"
                class="w-icon-24"
              /> Evaluator-Host History
            </h3>

          </div>
          <hr>
        </div>
      </div>
      <div
        v-if="loading"
        class="loading position-relative"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
      <div
        v-else
        class="table-responsive"
      >
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Institution</th>
              <th>Department</th>
              <!-- <th>Scientific contact</th> -->
              <th>Current evaluator</th>
              <th>Begin date</th>
              <th>End date</th>
              <th
                v-if="loggedUser.roles.includes('super-admin')"
                class="text-end"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(hostHistory, index) in evaluatorHostHistories.data.data"
              :key="index"
              class="h-57"
            >
              <td>{{ hostHistory.hostInstitution1 ? hostHistory.hostInstitution1.code : '-' }}</td>
              <td>{{ hostHistory.department1 ? hostHistory.department1.name : '-' }}</td>
              <!-- <td>{{ hostHistory.interlocutor }}</td> -->
              <td>{{ hostHistory.evaluator ? hostHistory.evaluator.name : '-' }}</td>
              <td>{{ hostHistory.begin_date }}</td>
              <td>{{ hostHistory.end_date_table }}</td>
              <td
                v-if="loggedUser.roles.includes('super-admin')"
                class="text-end"
              >
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                ><a
                  class="btn btn-icon rounded-circle btn-flat-dark"
                  @click="$router.push({name: 'researcher.administration.evaluator-history.edit', params: { id: hostHistory.id }})"
                ><i data-feather="edit-3" /></a></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="showingAll"
        class="card-body"
      >
        <div class="d-flex align-items-center justify-content-between">
          <b-pagination
            v-model="paginator.currentPage"
            :total-rows="evaluatorHostHistories.total"
            :per-page="paginator.perPage"
            aria-controls="table"
            @change="changePage"
          />
        </div>
      </div>
      <div
        v-if="evaluatorHostHistories.total > minValues"
        class="d-flex align-items-center justify-content-between"
      >
        <a
          class="btn btn-sm btn-flat-secondary"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Display all blocks"
          @click="showAllData"
        >
          {{ showingAll ? 'Show less' : 'Show all' }} <i data-feather="eye" /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BPagination,
  },
  data() {
    return {
      loading: true,
      paginator: {
        perPage: 10,
        currentPage: 1,
      },
      showingAll: false,
      minValues: 3,
      maxValues: 10,
    }
  },
  computed: {
    ...mapGetters({
      evaluatorHostHistories: 'users/evaluatorHostHistories',
      loggedUser: 'auth/admin',
      user: 'users/user',
    }),
  },
  async mounted() {
    await this.$store.dispatch('users/fetchUserEvaluatorHostHistories', { id: this.user.id })
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    this.loading = false
  },
  methods: {
    async showDeleteModal(id) {
      Vue.swal({
        title: 'Are you sure?',
        text: 'This action cannot be reversed',
        icon: 'warning',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        closeOnConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/host-histories/${id}`)
          Vue.swal('Deleted successfully!', '', 'success').then(() => {
            this.$store.dispatch('users/fetchUserHostHistories', { id: this.user.id })
            // eslint-disable-next-line no-undef
            feather.replace({
              width: 14,
              height: 14,
            })
          })
        }
      })
    },
    async changePage(page) {
      this.loading = true
      await this.$store.dispatch('users/fetchUserHostHistories', { id: this.user.id, page, perPage: this.maxValues })
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    async showAllData() {
      this.showingAll = !this.showingAll
      this.loading = true
      if (this.showingAll) {
        this.paginator.currentPage = 1
        await this.$store.dispatch('users/fetchUserHostHistories', { id: this.user.id, page: this.paginator.currentPage, perPage: this.maxValues })
      } else {
        await this.$store.dispatch('users/fetchUserHostHistories', { id: this.user.id, perPage: this.minValues })
      }
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
  },
}
</script>
