<template>
  <div
    id="salary"
    class="tab-pane"
    aria-labelledby="salary-tab"
    role="tabpanel"
  >
    <div class="row row__administrative-data d-flex align-items-stretch">
      <Salary />

      <PromotionHistory />

      <Supplements />

      <Dedication />

      <Residency />

      <TemporaryLeave />

      <UnpaidLeave />

      <HostHistory />

      <EvaluatorHostHistory />
    </div>
  </div>
</template>

<script>
import Salary from '@/views/back/partials/components/administrative-data/Salary.vue'
import Supplements from '@/views/back/partials/components/administrative-data/Supplements.vue'
import PromotionHistory from '@/views/back/partials/components/administrative-data/PromotionHistory.vue'
import UnpaidLeave from '@/views/back/partials/components/administrative-data/UnpaidLeave.vue'
import HostHistory from '@/views/back/partials/components/administrative-data/HostHistory.vue'
import EvaluatorHostHistory from '@/views/back/partials/components/administrative-data/EvaluatorHostHistory.vue'
import Dedication from '@/views/back/partials/components/administrative-data/Dedication.vue'
import Residency from '@/views/back/partials/components/administrative-data/Residency.vue'
import TemporaryLeave from '@/views/back/partials/components/administrative-data/TemporaryLeave.vue'

export default {
  components: {
    Salary,
    Supplements,
    PromotionHistory,
    UnpaidLeave,
    HostHistory,
    Dedication,
    Residency,
    TemporaryLeave,
    EvaluatorHostHistory,
  },

}
</script>
